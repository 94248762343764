import React from 'react';
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import MyUploader from '../../utility/component/uploader';
import CardTitle from '../../utility/cardTitle';
import { Star, StarFill } from 'react-bootstrap-icons';
import { SaveButton, UndoButton } from '../../utility/component/buttons';
const ItemRateDetails = ({
  titleStyles,
  hsnList,
  saveItemMasterAPI,
  intailData,
  itemData,
  setItemData,
}) => {
  const handleStarClick = (rating) => {
    setItemData((prev) => ({
      ...prev,
      ITEM_RATING: rating,
    }));
  };
  return (
    <Card className="w-full mt-4 p-4">
      <CardTitle title="Rate Details" />
      <div className="mt-4">
        <div className="flex">
          <FormGroup>
            <Label for="validationCustom02" className={titleStyles}>
              <span className="">*</span> MRP
            </Label>
            <Input
              value={itemData.MRP}
              onClick={(e) => e.target.select()}
              onChange={(e) =>
                setItemData((o) => {
                  return {
                    ...o,
                    MRP: e.target.value,
                  };
                })
              }
              id="validationCustom02"
              type="number"
              required
            />
          </FormGroup>
          <div className="ml-2">
            <FormGroup>
              <Label for="validationCustom02" className={titleStyles}>
                <span className="">*</span> Purchase Price
              </Label>
              <Input
                onClick={(e) => e.target.select()}
                value={itemData.PURCHASE_PRICE}
                onChange={(e) =>
                  setItemData((o) => {
                    return {
                      ...o,
                      PURCHASE_PRICE: e.target.value,
                    };
                  })
                }
                id="validationCustom02"
                type="number"
                required
              />
            </FormGroup>
          </div>
        </div>

        <div className="flex">
          <FormGroup>
            <Label for="validationCustom02" className={titleStyles}>
              <span className="">*</span> Sale Price
            </Label>
            <Input
              value={itemData.SALE_PRICE}
              onClick={(e) => e.target.select()}
              onChange={(e) =>
                setItemData((o) => {
                  return {
                    ...o,
                    SALE_PRICE: e.target.value,
                  };
                })
              }
              id="validationCustom02"
              type="number"
              required
            />
          </FormGroup>
          <div className="ml-2">
            <FormGroup>
              <Label for="validationCustom02" className={titleStyles}>
                <span className="">*</span> Discount
              </Label>
              <Input
                onClick={(e) => e.target.select()}
                value={itemData.DISCOUNT}
                onChange={(e) =>
                  setItemData((o) => {
                    return {
                      ...o,
                      DISCOUNT: e.target.value,
                    };
                  })
                }
                id="validationCustom02"
                type="number"
                required
              />
            </FormGroup>
          </div>
        </div>

        <FormGroup>
          <Label className={titleStyles}>
            <span>*</span> HSN Code
          </Label>
          <Input
            value={itemData.HSN_CODE}
            onChange={(e) =>
              setItemData((o) => {
                return {
                  ...o,
                  HSN_CODE: e.target.value,
                };
              })
            }
            id="validationCustom02"
            type="text"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="validationCustom02" className={titleStyles}>
            <span className="">*</span> Tax 1 rate
          </Label>
          <Input
            onClick={(e) => e.target.select()}
            value={itemData.TAX1_RATE}
            onChange={(e) =>
              setItemData((o) => {
                return {
                  ...o,
                  TAX1_RATE: e.target.value,
                };
              })
            }
            id="validationCustom02"
            type="text"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="validationCustom02" className={titleStyles}>
            <span className="">*</span> Tax 2 rate
          </Label>
          <Input
            onClick={(e) => e.target.select()}
            value={itemData.TAX2_RATE}
            onChange={(e) =>
              setItemData((o) => {
                return {
                  ...o,
                  TAX2_RATE: e.target.value,
                };
              })
            }
            id="validationCustom02"
            type="text"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="validationCustom02" className={titleStyles}>
            <span className="">*</span> Tax 3 rate
          </Label>
          <Input
            onClick={(e) => e.target.select()}
            value={itemData.TAX3_RATE}
            onChange={(e) =>
              setItemData((o) => {
                return {
                  ...o,
                  TAX3_RATE: e.target.value,
                };
              })
            }
            id="validationCustom02"
            type="text"
            required
          />
        </FormGroup>

        <div className="flex">
          <FormGroup>
            <Label for="validationCustom02" className={titleStyles}>
              <span className="">*</span> Opening Balance
            </Label>
            <Input id="validationCustom02" type="text" required />
          </FormGroup>
          <div className="ml-2">
            <FormGroup>
              <Label for="validationCustom02" className={titleStyles}>
                <span className="">*</span> Opening Date
              </Label>
              <Input
                value={itemData.OPBAL_DATE}
                onChange={(e) =>
                  setItemData((o) => {
                    return {
                      ...o,
                      OPBAL_DATE: e.target.value,
                    };
                  })
                }
                id="validationCustom02"
                type="date"
                required
              />
            </FormGroup>
          </div>
        </div>

        <FormGroup>
          <Label for="itemRating" className={titleStyles}>
            <span className="">*</span> Item Rating
          </Label>
          <div id="itemRating" className="flex hover:cursor-pointer">
            {[1, 2, 3, 4, 5].map((rating) => (
              <span key={rating} onClick={() => handleStarClick(rating)}>
                {rating <= itemData.ITEM_RATING ? (
                  <StarFill color="#ff8084" size={24} />
                ) : (
                  <Star color="#ff8084" size={24} />
                )}
              </span>
            ))}
          </div>
        </FormGroup>
        <div className="flex justify-end ">
          <SaveButton onClick={saveItemMasterAPI} />
          <UndoButton
            onClick={() => {
              setItemData(intailData);
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default ItemRateDetails;
