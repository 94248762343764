import React from 'react'

const CardTitle = ({title}) => {
  return (
    <div className='text-16 font-semibold border-b pb-4'>
   {title}
  </div>
  )
}

export default CardTitle