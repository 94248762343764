import React, { useState } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';

import uniqid from 'uniqid';

const ImageUpload = (props) => {
  const setSelectedFile = props.setSelectedFile;

  const handleDrop = (acceptedFiles) => {
    const editedFiles = acceptedFiles.map((file) => {
      const editedName = ` ${uniqid()}-${file.name}`;
      return new File([file], editedName, { type: file.type });
    });

    setSelectedFile(editedFiles[0]);

    // dispatch(setSelectedPhoto(acceptedFiles[0]));
  };

  //

  return (
    <div>
      <Dropzone onDrop={handleDrop}  accept="image/*">
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            style={{ border: '1px dashed black', padding: '10px' }}
          >
            <input {...getInputProps()} />

            <div style={{ color: '#f5874f', fontWeight: 'bold' }}>
              + Add Photo
              
            </div>
          </div>
        )}
      </Dropzone>
      {/* {selectedFile && (
        <div>
          <h4>Selected Image:</h4>
          <img src={URL.createObjectURL(selectedFile)} alt="Selected" style={{ width: '200px' }} />
          <button onClick={handleUpload}>Upload</button>
        </div>
      )} */}
    </div>
  );
};

export default ImageUpload;
