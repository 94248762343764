import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import moment from 'moment';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <div>
        <div className="print:hidden">
          <GridToolbarExport
            printOptions={{
              hideFooter: true,
            }}
          />
        </div>

        <CustomHeader />
      </div>
    </GridToolbarContainer>
  );
}

function CustomHeader() {
  return (
    <>
      <div className="w-full">
        <h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>
          Address List Master
        </h1>
      </div>
      <hr />
    </>
  );
}

const AddressReport = () => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      width: '100',
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: '200',
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'mobile',
      headerName: 'Phone',
      width: '200',
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'doe',
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
      headerName: 'Date Of Est.',
      width: '200',
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'caddress',
      renderCell: (p) => (
        <div>
          {p.row.caddress},{p.row.country},{p.row.city},{p.row.state},
        </div>
      ),
      headerName: 'Address',
      flex: 1,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const token = useSelector(selectToken);

  const getData = async () => {
    try {
      const response = await axios
        .post(
          '/api/masterReports/listAddressMaster',
          {
            code: '',
            name: '',
            city: '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          setRows(p.data.response);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="p-5">
        <DataGrid
          headerHeight={45}
          rowHeight={40}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          getRowId={(p) => p.code}
          style={{
            width: '100%',
            height: '500px',
            margin: 'auto',
            fontSize: '12px',
          }}
          rows={rows}
          columns={columns}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
};

export default AddressReport;
