import MDEditor from '@uiw/react-md-editor';
import React from 'react';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';
import CardTitle from '../../utility/cardTitle';

const ItemDesc = ({ value, onChange, titleStyles, itemData, setItemData }) => {
  return (
    <Card
      style={{
        width: '100%',
        marginTop: window.innerWidth < 800 ? '16px' : '0px',
      }}
      className=""
    >
      <CardBody>
        <CardTitle title="Item Description" />

        <FormGroup className=" mb-0 mt-4">
          <Label className={titleStyles}>Product Desc</Label>
          <div className="description-sm">
            <MDEditor
              value={itemData.ITEM_DESC}
              onChange={(e) => {
                console.log(e);
                setItemData((o) => {
                  return {
                    ...o,
                    ITEM_DESC: e,
                  };
                });
              }}
            />
          </div>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default ItemDesc;
