import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import {
  setAlertMsg,
  setALertStatus,
  setCustomeAlertButtonText,
  setCustomeAlertMsg,
  setCustomeAlertStatus,
  setCustomeAlertTitle,
} from "../utility/Redux/modal";

const VerifyEmail = () => {
  const searchParams = new URLSearchParams(window.location.search);
  // const param1 = searchParams.get('company_email');
  const userEmail = searchParams.get("user_email");
  const token = searchParams.get("token");
  const [status, setStatus] = useState("pending");
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const VerifyUser = async () => {
     const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(userEmail,secretPass)
// Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
  

    await axios
      .post(
        "api/users/userInfo",
        {
          user_email: userEmail,
        },
        {
          headers:  {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hmacString}`,
            
    },
        }
      )
      .then((res) => {
        if (res.data) {
          const user = res.data.response[0];
        
          if (user.token === token) {
            if (user.email_verified === "N") {
              axios
                .post(
                  "/api/users/emailVerified",
                  {
                    user_email: userEmail,
                  },
                  {
                           headers:  {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hmacString}`,
            
    },
                  }
                )
                .then((res) => {
                  setStatus("email verified");

                  axios
                    .post(
                      "/api/users/sendEmailToAdmin",
                      {
                        user_email: userEmail,
                        user_name: user.user_name,
                        activate_code: user.activate_code,
                      },
                      {
                        headers:  {
                         "Content-Type": "application/json",
                          Authorization: `Bearer ${hmacString}`,
            
    },
                      }
                    )
                    .then((res) => {
                      setStatus("email Verified & activation email send");
                      dispatch(setCustomeAlertTitle("Email Verified"));
                      dispatch(
                        setCustomeAlertMsg(
                          "Your account activation is in progress. Once completed, you will be able to sign into SalesDesk."
                        )
                      );
                      dispatch(setCustomeAlertButtonText("Ok, Sure !"));
                      dispatch(setCustomeAlertStatus(true));
                      navigate("/auth/login");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((e) => console.log(e));
            } else {
              setStatus("alreadyVerfied");
              dispatch(setAlertMsg("Email is already verified."));
              dispatch(setALertStatus(true));
              navigate("/auth/login");
            }
          } else {
            if (user.email_verified === "Y") {
              setStatus("alreadyVerfied");
              dispatch(setAlertMsg("Email is already verified."));
              dispatch(setALertStatus(true));
              navigate("/auth/login");
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (token) {
      VerifyUser();
    }
  }, []);
  return <div className="h-screen w-screen p-4">Account Status: {status}</div>;
};

export default VerifyEmail;
