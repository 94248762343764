import { createSlice } from '@reduxjs/toolkit';

export const Modals = createSlice({
  name: 'modals',
  initialState: {
    loading: {
      status: false,
    },
    alert: {
      status: false,
      msg: '',
      okStatus: 0,
      code: '',
    },
    templeModal: false,
    addressModal: false,
    isSessionExpired: false,

    checkEmailAlert: {
      status: false,
    },
    alertWithTitel: {
      status: false,
      title: '',
      msg: '',
      buttonText: 'Ok',
      lottie: '',
    },
    spinnerLoading: {
      status: false,
      text: 'loading',
    },
    checkVerifyEmailAlert: {
      status: false,
    },
    customeAlertMsg: {
      status: false,
      title: '',
      msg: '',
      buttonText: 'Ok',
    },

    customeTwoButtonAlert: {
      status: false,
    },
  },
  reducers: {
    setloading(state, action) {
      state.spinnerLoading.status=false

      state.loading.status = action.payload;
      // console.log('redux login updated '+ action.payload)
    },
    setAddressModalStatus(state, action) {
      state.spinnerLoading.status=false

      state.addressModal = action.payload;
    },
    setTempleModalStatus(state, action) {
      state.spinnerLoading.status=false

      state.templeModal = action.payload;
    },
    setAlertWithTitle(state, action) {
      state.spinnerLoading.status = false;
      state.loading.status = false;
      state.alertWithTitel.title = action.payload.title;
      state.alertWithTitel.msg = action.payload.msg;
      state.alertWithTitel.status = true;
      state.alertWithTitel.lottie = action.payload.lottie;
    },
    setAlertwithTitleModalStatus(state, action) {
      state.loading.status = false;
      state.alertWithTitel.status = action.payload ? action.payload : false;
    },
    setCustomeAlertButtonText(state, action) {
      state.spinnerLoading.status=false

      state.customeAlertMsg.buttonText = action.payload;
    },

    setCheckEmailAlert(state, action) {
      state.spinnerLoading.status=false

      state.checkEmailAlert.status = action.payload;
    },
    setCheckVerifyEmailAlert(state, action) {
      state.spinnerLoading.status=false

      state.checkVerifyEmailAlert.status = action.payload;
    },
    setALertStatus(state, action) {
      state.spinnerLoading.status=false

      state.alert.status = action.payload;
    },
    setCustomeAlertStatus(state, action) {
      state.spinnerLoading.status=false

      state.customeAlertMsg.status = action.payload;
    },
    setAlertMsg(state, action) {
      state.spinnerLoading.status=false

      state.alert.msg = action.payload;
    },
    setCustomeAlertMsg(state, action) {
      state.spinnerLoading.status=false

      state.customeAlertMsg.msg = action.payload;
    },
    setCustomeAlertTitle(state, action) {
      state.spinnerLoading.status=false
      state.customeAlertMsg.title = action.payload;
    },

    setSessionExpired(state, action) {
      state.isSessionExpired = action.payload;
    },
    setSpinnerLoading(state, action) {
      state.spinnerLoading.status = action.payload !== false ? true : false;
      state.spinnerLoading.text = action.payload;
    },
    setAlertOkStatus(state, action) {
      state.alert.okStatus = action.payload;
    },
    setCustomeTwoButtomAlert(state, action) {
      state.customeTwoButtonAlert.status = action.payload;
    },
  },
});

export default Modals.reducer;

export const {
  setCustomeAlertButtonText,
  setloading,setSpinnerLoading,
  setTempleModalStatus,
  setAlertOkStatus,
  setCustomeTwoButtomAlert,
  setSessionExpired,
  setAddressModalStatus,
  setCheckEmailAlert,
  setCheckVerifyEmailAlert,
  setALertStatus,setAlertwithTitleModalStatus,
  setAlertMsg,
  setCustomeAlertMsg,
  setCustomeAlertStatus,
  setCustomeAlertTitle,setAlertWithTitle
} = Modals.actions;

export const selectStatus = (state) => state.modals.loading.status;

export const selectCheckEmailStatus = (state) =>
  state.modals.checkEmailAlert.status;

export const selectCheckVerifyEmailStatus = (state) =>
  state.modals.checkVerifyEmailAlert.status;
export const SelectAlertStatus = (state) => state.modals.alert.status;
export const selectAlertMsg = (state) => state.modals.alert.msg;
export const selectSpinnerText = (state) => state.modals.spinnerLoading.text;

export const selectAlertOkStatus = (state) => state.modals.alert.okStatus;

export const selectCustomeAlertTitle = (state) =>
  state.modals.customeAlertMsg.title;
export const selectCustomeAlertStatus = (state) =>
  state.modals.customeAlertMsg.status;
export const selectCustomeAlertMsg = (state) =>
  state.modals.customeAlertMsg.msg;
export const selectCustomeAlertButton = (state) =>
  state.modals.customeAlertMsg.buttonText;
export const selectCustomeTwoButtomAlert = (state) =>
  state.modals.customeTwoButtonAlert.status;

/// work new from here

export const SelectToastStatus = (state) => state.modals.toast.status;

export const SelectToastMsg = (state) => state.modals.toast.msg;
export const SelectIsExpired = (state) => state.modals.isSessionExpired;
export const selectAddressModalStatus = (state) => state.modals.addressModal;
export const selectTempleModalStatus = (state) => state.modals.templeModal;
export const selectSpinnerLoading = (state) =>
  state.modals.spinnerLoading.status;
export const selectAlertWithTitleDetails = (state) =>
  state.modals.alertWithTitel;
export const selectAlertWithTitleModalStatus = (state) =>
  state.modals.alertWithTitel.status;