import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import './qrCss.css';
import { QRCodeSVG } from 'qrcode.react';
const INPUT_CLASS =
  'w-full px-3 py-2  bg-input dark:bg-primary border border-primary rounded-md focus:outline-none focus:ring focus:ring-primary';
const LABEL_CLASS = 'block text-sm mb-2';

const QRCodeGenerator = ({
  qrValue,
  setQrValue,
  filename,
  setFileName,
  downloadQRCode,
}) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-background dark:bg-primary">
      <div className="bg-card dark:bg-card-foreground rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold  mb-4">QR Code Generator</h2>
        <div className="mb-4">
          <label htmlFor="qr-value" className={LABEL_CLASS}>
            Enter QR Code Value:
          </label>
          <input
            id="qr-value"
            type="text"
            placeholder="E.g. https://example.com"
            value={qrValue}
            onChange={(e) => setQrValue(e.target.value)}
            className={INPUT_CLASS}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="file-name" className={LABEL_CLASS}>
            Enter File Name:
          </label>
          <input
            id="file-name"
            type="text"
            value={filename}
            onChange={(e) => setFileName(e.target.value)}
            placeholder="E.g. myQRCode"
            className={INPUT_CLASS}
          />
        </div>
        <button
          id="generate-btn"
          onClick={downloadQRCode}
          className="w-full bg-brown text-white rounded-md py-2"
        >
          Download QR Code
        </button>
        <div id="qr-code" className="mt-4 flex items-center justify-center">
          <div
            style={{
              padding: 10,
              width: 210,
              height: 210,
            }}
          >
            <QRCodeSVG size={200} value={qrValue} />
          </div>
        </div>
      </div>
    </div>
  );
};

const QrCodeSlip = () => {
  const qrRef = useRef();
  const [qrValue, setQrValue] = useState('');

  const [filename, setFileName] = useState('');

  const downloadQRCode = async () => {
    const canvas = await html2canvas(qrRef.current);
    const dataUrl = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${filename}.png`; // Name of the downloaded file
    link.click();
  };

  return (
    <div className="p-3">
      <QRCodeGenerator
        qrValue={qrValue}
        setQrValue={setQrValue}
        filename={filename}
        setFileName={setFileName}
        downloadQRCode={downloadQRCode}
      />
      {/* <div className="flex ">
        <div className="w-[110px]">Value</div> :
        <input
          type="text"
          value={qrValue}
          onChange={(e) => setQrValue(e.target.value)}
          className="ml-2 w-[300px]"
          placeholder="Enter URL or Text for QR Code"
        />
      </div>

      <div className="flex mt-2">
        <div className="w-[110px]">File Name</div> :
        <input
          type="text"
          value={filename}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="file Name"
          className="ml-2 w-[300px]"
        />
      </div> */}
      {/* <button onClick={downloadQRCode} style={{ marginTop: 10 }}>
        Download QR Code
      </button> */}
      <div>
        <div
          ref={qrRef}
          style={{
            padding: 50,
            width: 2100,
            height: 2100,
          }}
        >
          <div id={'print-container'}>
            <QRCodeSVG size={2000} value={qrValue} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeSlip;
