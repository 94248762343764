import React from 'react';
import CryptoJS from 'crypto-js';

const EncryptData = (data, passphrase) => {
  // Convert the data to a string
  const dataString = JSON.stringify(data);

  // Generate a random salt
  const salt = CryptoJS.lib.WordArray.random(16);

  // Derive a key and IV from the passphrase and salt
  const key = CryptoJS.PBKDF2(passphrase, salt, {
    keySize: 256 / 32,
    iterations: 1000,
    hasher: CryptoJS.algo.SHA256
  });

  // Generate a random IV
  const iv = CryptoJS.lib.WordArray.random(12);

  // Encrypt the data using ChaCha20
  const ciphertext = CryptoJS.chacha20.encrypt(dataString, key, { iv });

  // Combine the salt, IV, and ciphertext into a single string
  const encryptedData = `${salt.toString(CryptoJS.enc.Hex)}:${iv.toString(CryptoJS.enc.Hex)}:${ciphertext.toString()}`;

  // Return the encrypted data
  return encryptedData;
}

const  DecryptData = (encryptedData, passphrase) => {
  // Split the encrypted data into its components
  const [saltHex, ivHex, ciphertext] = encryptedData.split(':');

  // Convert the salt and IV to WordArray objects
  const salt = CryptoJS.enc.Hex.parse(saltHex);
  const iv = CryptoJS.enc.Hex.parse(ivHex);

  // Derive the key from the passphrase and salt
  const key = CryptoJS.PBKDF2(passphrase, salt, {
    keySize: 256 / 32,
    iterations: 1000,
    hasher: CryptoJS.algo.SHA256
  });

  // Decrypt the ciphertext using ChaCha20
  const plaintext = CryptoJS.chacha20.decrypt(ciphertext, key, { iv });

  // Convert the plaintext back to a JSON object
  const data = JSON.parse(plaintext.toString(CryptoJS.enc.Utf8));

  // Return the decrypted data
  return data;
}

export {EncryptData,DecryptData}

