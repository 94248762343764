import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { selectToken } from '../../utility/Redux/security';
import { selectAppId, selectPrefix } from '../../utility/Redux/profile';
import { toProperCase } from '../../utility/component/format';

import { useLocation, useNavigate } from 'react-router-dom';

import CommonBreadcrumb from '../../utility/component/breadCrumb';
import GenralItemDetails from './genralItemDetauils';
import ItemDesc from './itemDesc';
import ItemRateDetails from './itemrateDetail';
import moment from 'moment';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import { DownloadFile, UploadFile } from '../../utility/firebasse/firebase';
import uniqueid from 'uniqid';
import ItemMasterHelp from '../../help/itemMaster';
const ItemMaster = () => {
  const [selecedFile, setSelectedFile] = useState();
  const [selecedFile2, setSelectedFile2] = useState();
  const [selecedFile3, setSelectedFile3] = useState();
  const [selecedFile4, setSelectedFile4] = useState();
  const app_id = useSelector(selectAppId);
  const [sectionList, setSectionList] = useState([]);
  const [catogeryList, setCatogeryList] = useState([]);
  const [itemMasterhelpStatus, setItemMasterHelpStatus] = useState(false);
  const intailData = {
    ICODE: '',
    ITEMNAME: '',
    ITEM_DESC: '',
    DATE: moment().format('YYYY-MM-DD'),
    ITEM_SERIAL_NO: '',
    ALIAS_CODE: '',
    DISCOUNT: '0',
    CATEGORY: '',
    SECTION_NAME: '',
    COLOR: '',
    SIZE: '',
    UNIT: '',
    PACKING: '',
    PURCHASE_PRICE: '0',
    MRP: '0',
    SALE_PRICE: '0',
    TAX1_RATE: '0',
    TAX2_RATE: '0',
    TAX3_RATE: '0',
    HSN_CODE: '',
    BRAND: '',
    OPBAL: '0',
    OPBAL_DATE: moment().format('YYYY-MM-DD'),
    STOP_ITEM: 'NO',
    SUPPLIER_NAME: '',
    COMPANY_ID: app_id,
    ITEM_RATING: '0',
    IMG1_URL: '',
    IMG2_URL: '',
    IMG3_URL: '',
    IMG4_URL: '',
  };
  const [itemData, setItemData] = useState(intailData);
  const navigate = useNavigate();
  console.log(itemData);

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const colorList = [
    {
      COLOR: 'orange',
    },
  ];
  const location = useLocation();

  const titleStyles = 'text-gray-900 text-[15px]';
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const saveItem = (img1, img2, img3, img4) => {
    const data = {
      ...itemData,
      IMG1_URL: img1,
      IMG2_URL: img2,
      IMG3_URL: img3,
      IMG4_URL: img4,
      OPBAL_DATE: moment(itemData.OPBAL_DATE).format('YYYY-MM-DD'),
    };
    axios
      .post(`/api/ItemMasters/saveItemMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          setItemData(intailData);
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Data saved successfully',
              lottie: 'success',
            })
          );
          setSelectedFile('');
          setSelectedFile2('');
          setSelectedFile3('');
          setSelectedFile4('');
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 112',
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const saveItemMasterAPI = () => {
    if (itemData.ITEMNAME && itemData.SALE_PRICE) {
      const isDuplicateItemName = [].some(
        (row) => row.item_name === itemData.ITEMNAME
      );
      let imgurl1 = '';
      let imgurl2 = '';
      let imgurl3 = '';
      let imgurl4 = '';

      if (isDuplicateItemName) {
        // Handle the case where the item name already exists for new items
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Item Name already exists. Please choose a different Item name.',
          })
        );
        return; // Exit the function without saving
      }
      dispatch(setSpinnerLoading('Saving'));
      if (selecedFile) {
        const file = selecedFile;
        const name = `${prefix + uniqueid()}`;
        const path = `website_cp/images/`;
        UploadFile(file, path, name).then((p) => {
          DownloadFile(path + name).then((o) => {
            imgurl1 = o;
            if (selecedFile2) {
              const file = selecedFile2;
              const name = `${prefix + uniqueid()}`;
              const path = `website_cp/images/`;
              UploadFile(file, path, name).then((p) => {
                DownloadFile(path + name).then((o) => {
                  imgurl2 = o;
                  if (selecedFile3) {
                    const file = selecedFile3;
                    const name = `${prefix + uniqueid()}`;
                    const path = `website_cp/images/`;
                    UploadFile(file, path, name).then((p) => {
                      DownloadFile(path + name).then((o) => {
                        imgurl3 = o;
                        if (selecedFile4) {
                          const file = selecedFile4;
                          const name = `${prefix + uniqueid()}`;
                          const path = `website_cp/images/`;
                          UploadFile(file, path, name).then((p) => {
                            DownloadFile(path + name).then((o) => {
                              imgurl4 = o;
                              saveItem(imgurl1, imgurl2, imgurl3, o);
                            });
                          });
                        } else {
                          saveItem(imgurl1, imgurl2, imgurl3, imgurl4);
                        }
                      });
                    });
                  } else {
                    saveItem(imgurl1, imgurl2, imgurl3, imgurl4);
                  }
                });
              });
            } else {
              saveItem(imgurl1, imgurl2, imgurl3, imgurl4);
            }
          });
        });
      } else {
        saveItem(imgurl1, imgurl2, imgurl3, imgurl4);
      }
    } else {
      if (!itemData.SALE_PRICE) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'Please Fill Rate!',
          })
        );
      }
      if (!itemData.ITEMNAME) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'Please Fill Item Name!',
          })
        );
      }
    }
  };

  const getSectionDetails = () => {
    axios
      .post(
        `/api/ItemMasters/listSectionMaster`,
        {
          APP_ID: app_id,
          SECTION_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        console.log(response);
        if (!response.data.error) {
          setSectionList(response.data.response);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getCatogeryDetails();
      });
  };

  const getCatogeryDetails = () => {
    axios
      .post(
        `/api/ItemMasters/listCategoryMaster`,
        {
          APP_ID: app_id,
          CODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        console.log(response);
        if (!response.data.error) {
          setCatogeryList(response.data.response);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  useEffect(() => {
    getSectionDetails();
  }, []);

  useEffect(() => {
    if (location.state?.ITEMNAME) {
      setItemData(location.state);
    }
  }, [location]);
  return (
    <div className="p-4 h-full overflow-y-auto">
      <CommonBreadcrumb
        title="Add Products"
        parent="Masters"
        button={
          <div>
            <button
              onClick={() => {
                navigate('/masters');
              }}
              className="bg-brown p-1 px-4 rounded-sm text-[16px] text-white uppercase font-semibold mr-2"
            >
              Back
            </button>

            <button
              style={{
                wordSpacing: '2px',
              }}
              onClick={() => {
                navigate('/item_list');
              }}
              className="bg-[#ff8084] p-1 px-4 rounded-sm text-[16px] text-white uppercase font-semibold "
            >
              Item List
            </button>
          </div>
        }
      />
      <div className="t:flex mt-2 w-full">
        <div className="t:w-1/2">
          <GenralItemDetails
            setItemMasterHelpStatus={setItemMasterHelpStatus}
            selecedFile={selecedFile}
            setSelectedFile={setSelectedFile}
            selecedFile2={selecedFile2}
            setSelectedFile2={setSelectedFile2}
            selecedFile3={selecedFile3}
            setSelectedFile3={setSelectedFile3}
            selecedFile4={selecedFile4}
            setSelectedFile4={setSelectedFile4}
            colorList={colorList}
            sectionList={sectionList}
            catogeryList={catogeryList}
            itemData={itemData}
            setItemData={setItemData}
            titleStyles={titleStyles}
          />
        </div>
        <div className="t:w-1/2 t:ml-4 ">
          <ItemDesc
            itemData={itemData}
            setItemData={setItemData}
            titleStyles={titleStyles}
          />

          <ItemRateDetails
            intailData={intailData}
            saveItemMasterAPI={saveItemMasterAPI}
            itemData={itemData}
            setItemData={setItemData}
            titleStyles={titleStyles}
          />
        </div>
      </div>
      <ItemMasterHelp
        status={itemMasterhelpStatus}
        onRowClick={(p) => {
          setItemMasterHelpStatus(false);
          setItemData(p.row);
        }}
        handleClose={() => {
          setItemMasterHelpStatus(false);
        }}
      />
    </div>
  );
};

export default ItemMaster;
