import React, { useEffect, useState } from 'react';

import logo from '../utility/images/WGLOGO.png';

import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import AlertModal from '../utility/component/modals/alert';
import { useNavigate } from 'react-router-dom';

import { setAlertMsg, setALertStatus } from '../utility/Redux/modal';
import { SchemaChangedPassword } from './schemaValidate';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import CryptoJS from 'crypto-js';

export const ForgotPassword = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const user_email = searchParams.get('user_email');
  const token = searchParams.get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [istrusted, setTrusted] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  // const hmac = CryptoJS.HmacSHA256(message, secret);
  const hmac = CryptoJS.HmacSHA256(user_email, secretPass);
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = hmac.toString(CryptoJS.enc.Base64);

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: SchemaChangedPassword,
    onSubmit: (values) => {
      if (istrusted) {
        const data = {
          user_password: values.newPassword,
          user_email: user_email,
          token: token,
        };

        axios
          .post(`/api/users/changeUserPassword`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${hmacString}`,
            },
          })
          .then(function (response) {
            if (response.data.status === 200) {
              // localStorage.setItem('user',JSON.stringify(values))
              dispatch(setAlertMsg('Password Changed Successfully'));
              dispatch(setALertStatus(true));
              localStorage.clear('rememberd');
              navigate('/auth/login');
            } else {
              alert(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
            dispatch(setAlertMsg(error));
            dispatch(setALertStatus(true));
          });
      } else {
        dispatch(setAlertMsg('Not authorized for change password'));
        dispatch(setALertStatus(true));
      }
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => setShowPassword(false), 1000);
    return () => clearTimeout(timer);
  }, [showPassword]);

  const userData = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(e)) {
      axios
        .post(
          `/api/users/findUserDetail`,
          {
            user_email: e,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${hmacString}`,
            },
          }
        )
        .then(async function (response) {
          if (response.data[0].token === token) {
            setTrusted(true);
          } else {
            dispatch(setAlertMsg('Not authorized for change password'));
            dispatch(setALertStatus(true));
          }
        });
    }
  };

  useEffect(() => {
    if (user_email) {
      userData(user_email);
    }
  }, [user_email]);
  return (
    <div className=" items-center  flex w-screen bg-[#383535]  h-screen justify-center ">
      <div className=" py-7 w-auto h-auto  shadow-lg bg-white rounded-lg flex  justify-center">
        <AlertModal />

        <form>
          <div>
            <div className="flex  justify-center ">
              <div className="  flex justify-center  ">
                <div>
                  <div className="w-full   flex justify-center">
                    <div
                      style={{
                        marginTop: -70,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        height: 120,
                        width: 124,
                        backgroundColor: 'white',
                        borderRadius: 100,
                      }}
                    >
                      <img src={logo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ fontSize: 20 }}
              className="flex w-full justify-center text-black pt-6 pb-4 font-medium"
            >
              Change Password
            </div>

            <div className=" px-0.5">
              <div style={{ height: 1, backgroundColor: '#e0e0e0' }}></div>
            </div>

            <div className=" px-8">
              <div className="flex flex-col items-center pt-4 text-slate-600">
                Create atleast 6 digit strong
                <div>password</div>
              </div>

              <div>
                <div className="pt-3 flex justify-between  items-center">
                  <div className="w-full justify-center ">

                    {console.log(errors)}
                     
                    <div className="mt-2 flex items-center ">
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        value={values.newPassword}
                        size={'small'}
                        placeholder="New Password"
                        name="newPassword"
                        id="newPassword"
                        className="outline-0 border-0"
                        InputProps={{
                          // <-- This is where the toggle button is added.

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        error={
                          values.newPassword && errors.newPassword
                            ? true
                            : false
                        }
                        label={
                          values.newPassword
                            ? errors.newPassword
                            : 'New password'
                        }
                      />
                    </div>
                    <div className="mt-4 flex items-center ">
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        size={'small'}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          classes: {
                            error: {
                              color: '#f5874f',
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        id="confirmPassword"
                        onChange={handleChange}
                        error={
                          values.confirmPassword && errors.confirmPassword
                            ? true
                            : false
                        }
                        label={
                          values.confirmPassword
                            ? errors.confirmPassword
                            : 'Confirm Password'
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full  pt-5 flex items-center justify-center ">
                  <button
                    type="button"
                    onClick={() => {
                      if (!values.newPassword || errors.newPassword) {
                        dispatch(setAlertMsg('Please enter new password'));
                        dispatch(setALertStatus(true));
                      } else {
                        if (!values.confirmPassword || errors.confirmPassword) {
                          dispatch(
                            setAlertMsg('Please enter confirm password')
                          );
                          dispatch(setALertStatus(true));
                        } else {
                          handleSubmit();
                        }
                      }
                    }}
                    className="w-full"
                  >
                    <div className=" text-white font-bold flex justify-center py-2 rounded-lg bg-[#383535]">
                      Submit
                    </div>
                  </button>
                </div>

                <div className="flex justify-center text-slate-600 pt-5">
                  New user ?
                  <button onClick={() => navigate('/auth/signup')}>
                    <div className="pl-1 font-bold text-sm text-[#f5874f]">
                      SignUp
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
