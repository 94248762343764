import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const GeniusDatagrid = ({
  onDoubleClick,
  rowID,
  columns,
  list,
  editCell,
  onRowClick,
}) => {
  return (
    <div className="h-full w-full">
      {' '}
      <DataGrid
        hideFooterPagination
        hideFooter
        headerHeight={0}
        rowHeight={35}
        // scrollbarSize={0}
        onRowClick={onRowClick}
        showCellRightBorder={true}
        showColumnRightBorder={true}
        disableColumnMenu={true}
        onCellDoubleClick={onDoubleClick && onDoubleClick}
        getRowId={rowID}
        components={{
          Header: () => {
            const headerDetails = columns;
            return (
              <div
                key={() => 1}
                className="  bg-[rgb(56,53,53)] flex rounded-sm rounded-b-none"
              >
                {headerDetails?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" text-white flex justify-center items-center py-3"
                      style={
                        item.flex
                          ? index < headerDetails.length - 1
                            ? {
                                flex: item.flex,
                                borderRight: '1px solid #4a4948',
                                borderBottom: '1px solid #4a4948',
                              }
                            : {
                                flex: item.flex,
                                borderBottom: '1px solid #4a4948',
                              }
                          : index < headerDetails.length - 1
                          ? {
                              width: item.width,

                              borderRight: '1px solid white',
                              borderBottom: '1px solid #4a4948',
                            }
                          : {
                              width: item.width,
                              borderBottom: '1px solid #4a4948',
                            }
                      }
                    >
                      {item.renderHeader()}
                    </div>
                  );
                })}
              </div>
            );
          },
        }}
        sx={
          window.innerWidth < 770
            ? {
                backgroundColor: 'white',

                fontSize: 13,
                '& .super-app-theme--header': {
                  backgroundColor: '#f5f0e8',
                  display: 'none',
                },

                borderRadius: 2,
              }
            : {
                backgroundColor: 'white',
                fontSize: 15,
                borderRadius: 2,
                '& .super-app-theme--header': {
                  backgroundColor: '#f5f0e8',
                  borderRight: `1px solid #4a4948`,

                  display: 'none',
                },
              }
        }
        columns={columns}
        rows={list}
        pageSize={100}
        rowsPerPageOptions={[100]}
        disableColumnSelector
        disableColumnReorder
        onCellEditCommit={editCell && editCell}
      />
    </div>
  );
};

export default GeniusDatagrid;
