import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const expires = localStorage.getItem('expiresAt');
  const date = new Date().getTime();

  const UserTime = () => {
    if (expires >= date) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      return navigate('/auth/login');
    }
  };

  const checkUserToken = () => {
    const userToken = localStorage.getItem('tkn');

    if (userToken !== '') {
      UserTime();
    } else {
      setIsLoggedIn(false);
      return navigate('/auth/login');
    }
  };

  useEffect(() => {
    checkUserToken();
  }, []);
  return (
    <React.Fragment>
      {/* {props.children} */}

      {isLoggedIn ? props.children : null}
    </React.Fragment>
  );
};
export default ProtectedRoute;
