import { Button, Modal } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import logo2 from '../utility/images/G1.png';

const Loginui2 = ({
  setemail,
  location,
  passRef,
  hidden,
  VisibilityIcon,
  sethidden,
  VisibilityOffIcon,
  BsCheckSquare,
  remember,
  BsSquare,
  setRember,
  loginApi,
  forgetModel,
  dispatch,
  setAlertMsg,
  setALertStatus,
  move,
  AlertModal,
  loading,
  override,
  initialValues,
  BarLoader,
  forget,
  setForget,
  forgetPassword,
  CheckEmailModall,
  logo,
  email,
  EmailVerify,
  EmailVerified,
  setPasswordFocus,
  password,
  setpassword,
}) => {
  const windowWiidt = window.innerWidth;
  const [emailBordered, setEmailLoginBordered] = useState('gray');
  const [PasswordBordered, setPasswordBordered] = useState('gray');
  return (
    <div className="w-full flex justify-center items-center h-screen ">
      <div className="">
        <div className="flex">
          <div className="w-[200px] flex justify-center items-center">
            <div className=" flex flex-col items-center">
              <img src={logo} className="w-[150px]" />
            </div>
          </div>

          <div>
            <div className=" border-l   px-5">
              <div className="text-[19px] font-semibold">
                Login - <label className="font-bold"> Control Panel</label>
              </div>
              <div className=" flex justify-between mt-3  items-center">
                <div
                  style={{
                    width: windowWiidt >= 768 ? '300px' : '300px',
                  }}
                >
                  <div
                    style={{
                      border: `0.5px solid ${emailBordered}`,
                      borderRadius: '3px',
                      overflow: 'hideen',
                    }}
                  >
                    <input
                      autoSave={initialValues.Email}
                      onFocus={() => setEmailLoginBordered('#211adb')}
                      onBlur={() => setEmailLoginBordered('gray')}
                      size={'small'}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          setPasswordFocus();
                        }
                      }}
                      value={email}
                      style={{
                        fontSize: '13px',
                        borderRadius: '3px',
                        overflow: 'hideen',
                      }}
                      // pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
                      placeholder="Email Address"
                      name="Email"
                      id="Email"
                      onChange={(t) => {
                        setemail(t.target.value.toLowerCase());
                      }}
                      className="outline-0 w-full py-2 pl-3 "
                    />
                  </div>
                </div>
              </div>
              <div className=" flex justify-between mt-3  items-center">
                <div
                  style={{
                    width: windowWiidt >= 768 ? '300px' : '300px',
                  }}
                >
                  <div
                    style={{
                      border: `0.5px solid ${PasswordBordered}`,
                      borderRadius: '3px',
                      overflow: 'hideen',
                    }}
                  >
                    <input
                      autoSave={initialValues.Email}
                      onFocus={() => setPasswordBordered('#211adb')}
                      onBlur={() => setPasswordBordered('gray')}
                      size={'small'}
                      // onKeyDown={(event) => {
                      //   if (event.key === 'Enter') {
                      //     setPasswordFocus();
                      //   }
                      // }}
                      type="password"
                      ref={passRef}
                      value={password}
                      style={{
                        fontSize: '13px',
                        borderRadius: '3px',
                        overflow: 'hideen',
                      }}
                      // pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
                      name="login_password"
                      id="login_password"
                      placeholder="Password"
                      onChange={(p) => {
                        setpassword(p.target.value);
                      }}
                      className="outline-0 w-full py-2 pl-3 "
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-2">
                <input
                  checked={remember}
                  type="checkbox"
                  onChange={(e) => {
                    setRember(e.target.checked);
                  }}
                />
                <div className="text-sm ml-2">Remeber me</div>
              </div>
              <div className="mt-2">
                <Button
                  onClick={loginApi}
                  variant="contained"
                  style={{
                    textTransform: 'none',
                    letterSpacing: '1px',
                    backgroundColor: '#383535',
                    width: '100%',

                    height: '36px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '14px',
                    }}
                    className="text-white font-sans  whitespace-nowrap "
                  >
                    Login
                  </div>
                </Button>
              </div>

              <div className="flex mt-5 justify-between">
                <div
                  style={{
                    fontSize: '13px',
                  }}
                  className="flex  justify-center "
                >
                  New User ?{' '}
                  <button
                    onClick={() => {
                      move('/auth/signup');
                    }}
                  >
                    {' '}
                    <label className="pl-1  hover:cursor-pointer text-orange">
                      SignUp
                    </label>
                  </button>
                </div>
                <div className=" flex justify-end">
                  <button
                    onClick={() => {
                      if (email) {
                        forgetModel(email);
                      } else {
                        dispatch(
                          setAlertMsg(
                            'Please enter user email address for change password'
                          )
                        );
                        dispatch(setALertStatus(true));
                      }
                    }}
                  >
                    <div
                      style={{ fontSize: 13 }}
                      className="  flex justify-center whitespace-nowrap     text-slate-500"
                    >
                      Forgot
                      <label className="pl-1 font-medium  text-brown">
                        Password ?
                      </label>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertModal />
      <Modal open={forget} onClose={() => setForget(false)}>
        <div
          className="d:w-6/8 ml:w-11/12 xl:w-2/6 ms:w-11/12 md: l:w-3/6 bg-brown overflow-hidden rounded-lg t:w-4/6 w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start font-normal py-2 pl-3    d:bg-brown drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white py-3 ">
            <div className="flex  w-full px-5 pt-4   items-center ">
              <img
                src={require('../utility/images/ico.png')}
                className="mr-3 w-10 h-10 "
                alt=" "
              />
              <div>
                <div className="text-brown text-md  mb-4 ">
                  <div> Click Continue to reset your Password.</div>
                  <div>
                    Reset Password link has been sent on your Email :
                    <div className="font-semibold">{email}</div>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex  justify-end  bg-white pr-3 ">
              <Button
                size={'small'}
                onClick={() => {
                  forgetPassword(email);
                  setForget(false);
                }}
                color="secondary"
                style={{ marginRight: 4 }}
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Continue
                </div>
              </Button>

              <Button
                size={'small'}
                onClick={() => setForget(false)}
                color="secondary"
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Cancel
                </div>
              </Button>
            </div>
            {/* <div>
     <input 
       type={'text'}
       className= {'w-10'}
       />
     </div> */}
          </div>
        </div>
      </Modal>
      <CheckEmailModall />
      <EmailVerify email={location.state} />

      <EmailVerified />
    </div>
  );
};

export default Loginui2;
