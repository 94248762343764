import { Button, Radio } from '@mui/material';

const ModifyButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#ebaf81',
        }}
      >
        <div className="text-black font-sans px-3">Modify </div>
      </Button>
    </>
  );
};

const AlertOkButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#1c1a1a',
          width: '100%',
          marginLeft: 4,
        }}
      >
        <div className="text-white   px-3 ">Ok </div>
      </Button>
    </>
  );
};


const SaveButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
        }}
      >
        <div className="text-black px-4 font-sans">Save </div>
      </Button>
    </>
  );
};

const CustomeButton = ({ onClick, title, backgroundColor, style }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          ...style,
          textTransform: 'none',
          letterSpacing: '1px',

          backgroundColor: backgroundColor ? backgroundColor : '#a8cf45',
        }}
      >
        <div
          style={{ ...style, display: 'flex' }}
          className="text-black  font-sans"
        >
          {title}{' '}
        </div>
      </Button>
    </>
  );
};

const PrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#f5874f',
        marginRight: 3,
      }}
    >
      <div className="text-black px-4 font-sans ">Print </div>
    </Button>
  );
};

const SavePrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#f5874f',
        marginRight: 3,
      }}
    >
      <div className="text-black px-4 font-sans "> Save & Print </div>
    </Button>
  );
};

const OkButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
          marginLeft: 4,
        }}
      >
        <div className="text-black font-sans  px-3 ">Ok </div>
      </Button>
    </>
  );
};

const UndoButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
          marginLeft: 4,
        }}
      >
        <div className="text-white font-sans px-3  ">Undo </div>
      </Button>
    </>
  );
};
const ExitButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#f1f5f9',
          marginLeft: 4,
        }}
      >
        <div className="text-black font-sans px-2 ">Exit </div>
      </Button>
    </>
  );
};

const BackButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#f1f5f9',
          marginLeft: 4,
        }}
      >
        <div className="text-black font-sans  whitespace-nowrap ">
          {' '}
          {' < '} Back{' '}
        </div>
      </Button>
    </>
  );
};

const GeniusOfficeButton = ({ onClick, title, color, checked }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          boxShadow: 'none',
        }}
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: color ? color : '#f1f5f9',
          marginLeft: 4,
          padding: 0,
        }}
      >
        <div
          style={{
            fontSize: 13,
          }}
          className="text-black font-sans  whitespace-nowrap flex items-center   pr-3 "
        >
          <Radio
            checked={checked}
            color="success"
            size="small"
            sx={{ backgroundColor: 'white', color: 'white' }}
          />
          {title}
        </div>
      </Button>
    </>
  );
};

export {
  ModifyButton,
  PrintButton,
  SaveButton,
  UndoButton,
  OkButton,
  ExitButton,
  BackButton,
  GeniusOfficeButton,
  SavePrintButton,
  CustomeButton,AlertOkButton
};
