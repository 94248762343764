import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { CustomeButton } from '../utility/component/buttons';
import {
  setALertStatus,
  setAlertMsg,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import DatePicker from 'react-datepicker';
import { CalendarMonth } from '@mui/icons-material';
import { parseISO } from 'date-fns';
import Lottie from 'lottie-react';
import noData from '../utility/json/noData.json';
import { selectAppId, selectPageId, setPageId } from '../utility/Redux/profile';

const ApprovePost = () => {
  const [posts, setPosts] = useState([]);
  const [approveedIdListState, setApprovedIdList] = useState([]);
  const [pageAccessToken, setPageAccess] = useState('');

  const [refreshPage, setRefreshPage] = useState(false);

  const [secondDateFocus, SetSecondDateFocus] = useState(false);
  const [firstdateFocus, setfirstDateFocus] = useState('');
  const [firstDate, setFirstDate] = useState(
    moment().subtract(1, 'year').format('YYYY-MM-DD')
  );
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));

  const dispatch = useDispatch();
  // const pageId = '225325054344947';
  const pageId = useSelector(selectPageId);
  const appId = useSelector(selectAppId);
  const token = useSelector(selectToken);

  useEffect(() => {
    getApprovedPosts();
  }, [refreshPage, firstDate, SecondDate]);

  // get our allready approved list
  const getApprovedPosts = () => {
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        '/api/list_Fb_Posts/list_Fb_Posts',
        {
          from_date: moment(firstDate).format('YYYY-MM-DD'),
          to_date: moment(SecondDate).format('YYYY-MM-DD'),
          app_id: appId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log(res);
        // const stringWithSpaces = ' india sikh community india ';
        // const stringWithoutSpaces = stringWithSpaces.replace(/\s+/g, '');
        // console.log(stringWithoutSpaces);
        if (!res.data.error) {
          const list = res.data.response;
          const selfList = [];

          const approvedPostIdList = list.map((response) => {
            const data = {
              id: response.id,
              approved: response.approved,
              app_id: appId,
            };

            if (response.post_type === 'SELF') {
              selfList.push(response);
            }
            return data;
          });

          let s = selfList;
          console.log(s);
          setApprovedIdList(approvedPostIdList);
          // if page access is not empty then get Fb Post else get page token
          if (pageAccessToken) {
            getFacebookPosts(approvedPostIdList, s, pageAccessToken, pageId);
          } else {
            GetPageToken(approvedPostIdList, s);
          }
          dispatch(setSpinnerLoading(false));
        } else {
          console.log(res.data.error);
          dispatch(setSpinnerLoading(false));
        }
      })
      .catch((err) => {
        if (err.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(setSpinnerLoading(false));

        console.log(err);
      })
      .finally(() => {});
  };

  const GetPageToken = (approvedPostIdList, s) => {
    if (appId) {
      axios
        .post(
          `/api/list_Fb_Posts/get_page_Token`,
          // `/api/list_Fb_Posts/get_page_Token`,
          {
            app_id: appId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoiaXRzd2Vhcmdvb2RAZ21haWwuY29tIiwiaWF0IjoxNjg2NDAwODYzLCJleHAiOjE2ODY0MDQ0NjN9.a1D-pMyRxyMICXM_cCSKeZNPf9s7x-LkDQaWJNxir30`,
              // Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            const page = response.data.page_id;
            const pagetoken = response.data.page_token;
            setPageAccess(pagetoken);
            dispatch(setPageId(page));
            getFacebookPosts(approvedPostIdList, s, pagetoken, page);
          }
          //   if (!response.data.error) {
          //     console.log()
          //   }
        })
        .catch((err) => {
          dispatch(setAlertMsg('something went wrong'));
          dispatch(setALertStatus(true));
          console.error(err);
        });
    }
  };

  const getFacebookPosts = async (approvedPostIdList, s, pageTokan, page) => {
    const response = await axios.get(
      `https://graph.facebook.com/v12.0/${page}/feed?fields=message,created_time,attachments&access_token=${pageTokan}&since=${firstDate}&until=${SecondDate}`
    );

    const { data } = response;

    const fbpost = data.data;
    console.log(fbpost);
    let pendingPosts = [];
    console.log(response);
    // for update post approved status we run for loop

    for (let index = 0; index < fbpost.length; index++) {
      // use filter and then try to find
      const isIncluded = approvedPostIdList.filter(
        (item) => item.id === fbpost[index].id
      );

      // if list of our database store posts is not included the fb post then push it into pendingPosts list with approved = no
      if (isIncluded.length < 1) {
        pendingPosts.push({
          ...fbpost[index],
          approved: 'NO',
        });
      } else {
        // if list of our database store posts is have same fb post then push it into pendingPosts list with approved =  his actual value
        let i = approvedPostIdList.findIndex(
          (item) => item.id === fbpost[index].id
        );

        pendingPosts.push({
          ...fbpost[index],
          approved: approvedPostIdList[i].approved,
        });
      }
    }

    setPosts([...pendingPosts, ...s]);
  };

  const approvePost = (data, status, img) => {
    console.log(data);
    // const video = data.attachments?.data[0]?.media?.source;
    dispatch(setSpinnerLoading('Loading'));
    const video =
      data.attachments?.data[0]?.type === 'video_autoplay'
        ? data.attachments?.data[0]?.url
        : null;
    const image = data.attachments?.data[0]?.media?.image?.src;
    const date = moment(data.created_time).format('YYYY-MM-DD');
    const id = data.id;

    const message = data.message;

    let list = [];

    list.push({
      company_name: 'ISCI',
      message: message ? message : '#Indian Sikh Community Itly',
      image: image ? image : null,
      video: video ? video : null,
      date: date,
      id: id,
      approved: status,
      post_type: 'FB',
      app_id: appId,
    });

    const IsApprovedPost = approveedIdListState.filter(
      (state) => state.id === id
    );

    if (IsApprovedPost.length > 0) {
      axios
        .post(
          '/api/fb_Posts/update_Fb_Posts',
          {
            id: IsApprovedPost[0].id,
            approved: status,
            image: img ? img : '',
            app_id: appId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((res) => console.log('res'))
        .catch((err) => {
          if (err.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(err);
        })
        .finally(() => {
          setRefreshPage((prev) => !prev);
          dispatch(setSpinnerLoading(false));
        });
    } else {
      axios
        .post(
          '/api/fb_Posts/save_Fb_Posts',
          { list },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'application/json;  charset=UTF-8',
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((res) => console.log(res))
        .catch((err) => {
          if (err.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(err);
        })

        .finally(() => {
          setRefreshPage((prev) => !prev);
          dispatch(setSpinnerLoading(false));
        });
    }
  };

  return (
    <div className="w-full    bg-[#ffffff]  h-full p-3 ">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontSize: 20,
          justifyContent: 'start',
          backgroundColor: '#ffffff',
        }}
      >
        <div className=" flex flex-row items-center p-1 justify-center ">
          <div
            style={{ fontSize: 13 }}
            className=" font-semibold pr-2  text-[#4B4545] whitespace-nowrap items-center"
          >
            From :
          </div>

          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="flex rounded-[7px] overflow-hidden"
          >
            <div className="flex items-center  bg-[#f1efeb]">
              <DatePicker
                onFocus={() => setfirstDateFocus(true)}
                onClickOutside={() => setfirstDateFocus(false)}
                onSelect={() => setfirstDateFocus(false)}
                open={firstdateFocus}
                className=" bg-transparent text-sm w-40 flex items-center  text-center mb-2"
                dateFormat=" d MMMM , yyyy "
                selected={parseISO(firstDate)}
                onChange={(date) =>
                  setFirstDate(moment(date).format('YYYY-MM-DD'))
                }
              />
            </div>

            <div className=" items-center px-1 flex justify-start bg-[#f7dda4] border-l border-l-[#b5b3b3]">
              <CalendarMonth
                color={'secondary'}
                sx={{ opacity: 0.9 }}
                fontSize="small"
                onClick={() => {
                  setfirstDateFocus(true);
                }}
              />
            </div>
            <div></div>
          </div>
          <div
            style={{ fontSize: 13 }}
            className="font-semibold text-[#4B4545] pr-2 pl-8 items-center whitespace-nowrap"
          >
            To :
          </div>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="flex rounded-[7px] overflow-hidden "
          >
            <div className="bg-[#f1efeb] ">
              <DatePicker
                onFocus={() => SetSecondDateFocus(true)}
                onClickOutside={() => SetSecondDateFocus(false)}
                onSelect={() => SetSecondDateFocus(false)}
                open={secondDateFocus}
                style={{ fontSize: 13 }}
                className=" bg-transparent w-40 text-sm mb-2  "
                dateFormat=" d MMMM , yyyy "
                selected={parseISO(SecondDate)}
                onChange={(date) =>
                  setSecondDate(moment(date).format('YYYY-MM-DD'))
                }
              />
            </div>

            <div className="items-center px-1 flex justify-start bg-[#f7dda4] border-l border-l-[#b5b3b3]">
              <CalendarMonth
                onClick={() => {
                  SetSecondDateFocus(true);
                }}
                color={'secondary'}
                sx={{ opacity: 0.9 }}
                fontSize="small"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: `calc(100% - 60px)`,
        }}
        className="w-full mt-3 "
      >
        {console.log(posts)}
        <div className="grid grid-cols-4 gap-4  h-full overflow-y-scroll pr-1">
          {posts[0]
            ? posts.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col justify-between"
                    style={{
                      padding: 20,
                      paddingTop: 20,
                      backgroundColor: '#ebe5e4',
                      borderRadius: 10,
                    }}
                  >
                    <div className="flex flex-col" style={{ marginBottom: 20 }}>
                      <div className="flex justify-center">
                        {data.image ? (
                          <img
                            src={data.image}
                            style={{
                              // width: '80%',
                              // maxHeight: '40%',
                              // maxHeight: '400px',
                              height: '250px',
                            }}
                          />
                        ) : (
                          <img
                            src={data.attachments?.data[0].media?.image?.src}
                            style={{
                              // width: '80%',
                              // maxHeight: '40%',
                              height: '250px',
                            }}
                          />
                        )}
                      </div>

                      <div style={{ marginTop: 15 }} className="flex">
                        <p
                          style={{
                            marginBottom: 20,
                            fontSize: 14,
                            color: 'grey',
                          }}
                        >
                          Created :{' '}
                        </p>
                        <p
                          className="pl-2  "
                          style={{
                            marginBottom: 20,
                            fontSize: 14,
                            color: 'grey',
                          }}
                        >
                          {moment(data.created_time).format('DD-MMM-YYYY')}
                        </p>
                      </div>
                      <div>
                        <p style={{ fontSize: 15 }}>{data.message}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10,

                        alignItems: 'center',
                      }}
                    >
                      <CustomeButton
                        disabled={data.approved === 'YES' ? true : false}
                        title={data.approved === 'YES' ? 'Approved' : 'Approve'}
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          if (data.approved === 'NO') {
                            approvePost(
                              data,
                              'YES',
                              data.attachments?.data[0].media?.image?.src
                            );
                          }
                        }}
                        backgroundColor={
                          data.approved === 'YES' ? '#d6cfce' : '#a8cf45'
                        }
                      />

                      <CustomeButton
                        disabled={data.approved === 'NO' ? true : false}
                        title={'Reject'}
                        style={{
                          marginTop: '5px',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                        backgroundColor={
                          data.approved === 'YES' ? '#f5612a' : '#d6cfce'
                        }
                        onClick={() => {
                          if (data.approved === 'YES') {
                            approvePost(
                              data,
                              'NO',
                              data.attachments?.data[0].media?.image?.src
                            );
                          }
                        }}
                      />

                      {/* <CheckBoxOutlineBlank
                    style={{ marginLeft: 20 }}
                    sx={{ color: '#ff6f0f' }}
                  />
                  <p style={{ marginLeft: 5, color: '#d6cfce' }}>
                    Add on Home Screen
                  </p> */}

                      {/* <button type="button" style={{marginLeft:10}} className="btn btn-secondary btn-sm">Reject</button> */}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {posts.length < 1 ? (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'center',

              marginTop: 60,

              marginBottom: 100,
            }}
          >
            {' '}
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {' '}
              <Lottie
                style={{ height: 'auto', width: '20%', marginBottom: 30 }}
                animationData={noData}
                loop={true}
              />
              <div style={{ color: '#6c757d', fontWeight: 600 }}>
                {' '}
                There are no activities for the selected year.{' '}
              </div>
            </div>
          </div>
        ) : null}

        <video
          src={'https://fb.watch/lalxselEtK/'}
          style={{ height: '200px', width: '300px' }}
        ></video>
      </div>
    </div>
  );
};

export default ApprovePost;
