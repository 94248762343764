import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppId, selectPrefix } from '../../utility/Redux/profile';
import { selectToken } from '../../utility/Redux/security';
import { useFocus } from '../../utility/hooks/hooks';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toProperCase } from '../../utility/component/format';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../../utility/component/buttons';
import ModalHeaderStyle from '../../utility/component/modalheaderStyle';
import GeniustextInput from '../../utility/component/geniustextInput';
import GeniusDatagrid from '../../utility/component/geniusDatagrid';
import { Input, Label } from 'reactstrap';

const CatogeryMaster = () => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const windowWidth = window.innerWidth;
  const [id, setId] = useState();
  const [secName, setSecName] = useState();
  const [rows, setRows] = useState([]);
  const [secError, setSecError] = useFocus();

  const columns = [
    {
      field: 'CATEGORY',
      type: 'string',
      renderHeader: (params) => <div>Catogery Name</div>,
      width: windowWidth > 764 ? 400 : 300,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const getData = async () => {
    // dispatch(setSpinnerLoading('Loading'));
    dispatch(setSpinnerLoading('Loading'));
    await axios
      .post(
        '/api/ItemMasters/listCategoryMaster',
        {
          APP_ID: app_id,
          CODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        setSecError();
        dispatch(setSpinnerLoading(false));
      });
  };
  const app_id = useSelector(selectAppId);

  useEffect(() => {
    getData();
  }, []);
  const saveSectionMasterApi = () => {
    if (secName) {
      const isDuplicateMobile = rows.some((row) => row.CATEGORY === secName);

      if (isDuplicateMobile) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Section is already exists. Please choose a different section.',
          })
        );
        return;
      }

      const data = {
        COMPANY_ID: app_id,
        CODE: id ? id : '',
        CATEGORY: secName,
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/itemMasters/saveCategoryMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
            console.log(error.response);
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          setSecName('');
          setId('');
          getData();
        });
    } else {
      if (!secName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Section Name.',
            lottie: 'reject',
          })
        );
        setSecError();
      }
    }
  };
  const navigate = useNavigate();
  return (
    <div className="flex justify-center pt-4">
      <div className=" bg-white  rounded-sm shadow-sm  border-brown ">
        <ModalHeaderStyle
          header={`Catogery Master`}
          onClose={() => {
            navigate('/masters');
          }}
        />

        <div id="input" className="flex mt-4 px-5 ">
          <div>
            <Label for="validationCustom02">Catogery</Label>

            <Input
              style={{
                width: windowWidth > 764 ? '400px' : '300px',
              }}
              type={'text'}
              //   search={handleOpenDeptHelp}
              value={secName}
              onChange={(e) => {
                setSecName(toProperCase(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="px-5  mt-3">
          <div
            style={{
              width: columns[0].width + 0,
              height: window.innerHeight - 500,
            }}
            className=" mt-1 flex "
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              onRowClick={(p) => {
                setId(p.row.CODE);
                setSecName(p.row.SECTION_NAME);
              }}
              columns={columns}
              list={rows}
            />
          </div>

          <div
            style={{
              width: columns[0].width + 0,
            }}
            className="flex  justify-end items-center py-3"
          >
            {id ? (
              <ModifyButton onClick={saveSectionMasterApi} />
            ) : (
              <SaveButton onClick={saveSectionMasterApi} />
            )}

            <UndoButton
              onClick={() => {
                setSecName('');
                setId('');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatogeryMaster;
