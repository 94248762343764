import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import { selectAppId, selectWidth } from '../utility/Redux/profile';
import { setSessionExpired } from '../utility/Redux/modal';
import MasterHelp from './masterhelp';

const ItemMasterHelp = ({ onRowClick, filterBy, status, handleClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);
  const width = useSelector(selectWidth);

  const columns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 150 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div className="text-md">Item Name</div>,
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'SECTION_NAME',
      renderHeader: (params) => <div className="text-md">Section Name</div>,
      width:
        width <= 768 ? 60 : width <= 1024 ? 100 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];
  const [loading, setloading] = useState(true);

  const dispatch = useDispatch();
  const app_id = useSelector(selectAppId);

  const getData = async () => {
    // try {
    //   const response =
    await axios
      .post(
        `/api/ItemMasters/listItemMaster`,
        {
          APP_ID: app_id,
          ICODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (filterBy) {
          const data = p.data.response.filter(
            (e) => e.upper_lower === filterBy
          );
          setRows(data);

          setFullList(data);
        } else {
          setRows(p.data.response);

          setFullList(p.data.response);
        }
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    getData();
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.icode.toString().includes(searchQuery);
        } else {
          return item.item_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            setRows([]);
            setFullList([]);
            handleClose();
          }}
          title={'Item Master | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.ICODE}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
            setFullList([]);
          }}
        />
      </div>
    </>
  );
};

export default ItemMasterHelp;
