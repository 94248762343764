import { Button } from '@mui/material';
import React, { useState } from 'react';
import {
  BackButton,
  ExitButton,
  GeniusOfficeButton,
  SaveButton,
} from '../utility/component/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import {
  selectAppId,
  selectPageId,
  setAppId,
  setPageId,
} from '../utility/Redux/profile';
import { setALertStatus, setAlertMsg } from '../utility/Redux/modal';

const width = window.innerWidth / 100;
const UpdateKey = () => {
  const app_id = useSelector(selectAppId);
  const page_id = useSelector(selectPageId);
  // const [appId, setAppId] = React.useState('');
  const [border, setBorderColor] = React.useState('#e2e8f0');
  const [border2, setBorderColor2] = React.useState('#e2e8f0');
  const [border3, setBorderColor3] = React.useState('#e2e8f0');
  const [border4, setBorderColor4] = React.useState('#e2e8f0');
  const dispatch = useDispatch();
  // const [pageId, setPageId] = useState(page_id);
  const [Secretkey, setSecretKey] = React.useState('');
  const [AccessKey, setAccessKey] = React.useState('');
  const token = useSelector(selectToken);
  const save = () => {
const data ={ app_id: app_id,
  page_id: page_id,
  secret_key: Secretkey,
  short_access_token: AccessKey,}

console.log(data)
if (app_id && page_id && AccessKey && Secretkey) {
      axios
        .post(
          // `http://localhost:8080/api/list_Fb_Posts/get_Fb_Details`,
          `/api/list_Fb_Posts/get_Fb_Details`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoiaXRzd2Vhcmdvb2RAZ21haWwuY29tIiwiaWF0IjoxNjg2NDAwODYzLCJleHAiOjE2ODY0MDQ0NjN9.a1D-pMyRxyMICXM_cCSKeZNPf9s7x-LkDQaWJNxir30`,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch(setAlertMsg('Request accepted'));
          dispatch(setALertStatus(true));
        })
        .catch((err) => console.error(err));
    } else {
      dispatch(setAlertMsg('Please fill required fields'));
      dispatch(setALertStatus(true));
    }
  };

  return (
    <div className="w-full h-full  p-6 bg-white">
      <div className=" w-full">
        <div className="text-lg font-bold mb-5">Update Key</div>
        <div className="flex items-start pb-1" style={{ width: width * 12 }}>
          App Id <div className="text-red-500 text pl-1"> *</div>
        </div>

        <div
          style={{ borderColor: border }}
          className=" py-1 bg-[#f5f8fa] px-2 border w-full   rounded-sm mb-5"
        >
          <input
            type="text"
            value={app_id}
            onFocus={() => setBorderColor('#94a3b8')}
            onBlur={() => setBorderColor('#e2e8f0')}
            className="bg-[#f5f8fa] outline-0 w-full "
            readOnly={true}
            // onChange={(t) => dispatch(setAppId(t.target.value))}
          />
        </div>
      </div>

      <div className=" pr-1 w-full">
        <div className="flex items-start pb-1" style={{ width: width * 12 }}>
          Page Id <div className="text-red-500 text pl-1"> *</div>
        </div>

        <div
          style={{ borderColor: border2 }}
          className=" py-1 bg-[#f5f8fa] px-2 border w-full   rounded-sm mb-5"
        >
          <input
            type="text"
            value={page_id}
            onFocus={() => setBorderColor2('#94a3b8')}
            onBlur={() => setBorderColor2('#e2e8f0')}
            className="bg-[#f5f8fa] outline-0 w-full"
            readOnly={true}
            // onChange={(t) => dispatch(setPageId(t.target.value))}
          />
        </div>
      </div>

      <div className=" pr-1 w-full">
        <div className="flex items-start pb-1" style={{ width: width * 12 }}>
          Secret Key <div className="text-red-500 text pl-1"> *</div>
        </div>

        <div
          style={{ borderColor: border3 }}
          className=" py-1 bg-[#f5f8fa] px-2 border w-full   rounded-sm mb-5"
        >
          <input
            type="text"
            value={Secretkey}
            onFocus={() => setBorderColor3('#94a3b8')}
            onBlur={() => setBorderColor3('#e2e8f0')}
            className="bg-[#f5f8fa] outline-0 w-full "
            onChange={(t) => setSecretKey(t.target.value)}
          />
        </div>
      </div>

      <div className=" pr-1 w-full">
        <div className="flex items-start pb-1" style={{ width: width * 12 }}>
          Access Token <div className="text-red-500 text pl-1"> *</div>
        </div>

        <div
          style={{ borderColor: border4 }}
          className=" py-1 bg-[#f5f8fa] px-2 border w-full   rounded-sm mb-5"
        >
          <input
            type="text"
            value={AccessKey}
            onFocus={() => setBorderColor4('#94a3b8')}
            onBlur={() => setBorderColor4('#e2e8f0')}
            className="bg-[#f5f8fa] outline-0 w-full "
            onChange={(t) => setAccessKey(t.target.value)}
          />
        </div>
      </div>
      <div className="w-full flex justify-end">
        <SaveButton onClick={save} />
        <ExitButton />
      </div>
      <div className="flex w-full text-sm text-slate-500 pt-8 justify-center">
        Please retain this information for future reference, specifically when
        your token has expired.
      </div>
    </div>
  );
};

export default UpdateKey;
