import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { selectCheckEmailStatus, setCheckEmailAlert } from "../../Redux/modal";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/ico.png";

export default function CheckEmailModall() {
  const dispatch = useDispatch();
  const checkEmailStatus = useSelector(selectCheckEmailStatus);

  const handleClose = () => dispatch(setCheckEmailAlert(false));

  return (
    <div>
      <Modal open={checkEmailStatus} onClose={handleClose}>
        <div
          className="d:w-4/12 border-1 ml:w-11/12 mm:w-11/12 ms:w-11/12   l:w-6/12 t:w-4/6 bg-[#A9A9A9] overflow-hidden rounded-lg   "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            divShadow: 24,
          }}
        >
          <div className=" flex  flex-col justify-center py-5">
            <div className="flex  px-5  flex flex-col  justify-center items-center  ">
              <img
                src={require("../../images/WGLOGO.png")}
                className=" w-24  -mt-0.5 "
                alt=" "
              />
              {/* <img src = {require('../../images/GO92X12.png' )} style={{height:20}}  alt=' ' /> */}
              {/* <span style={{fontSize:8 ,fontWeight:'100' ,letterSpacing:1 }} className='text-slate-50 '>Since 1994.</span> */}
              <div className="text-black mt-2 mb-4 font-arialRounded ">
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                  Reset Your Password
                </span>
              </div>
            </div>

            <div className="flex text-slate-200 tracking-wider font-arial flex-col justify-center bg-[#242222] text-center px-10 rounded-lg mx-5 py-4   ">
              <span
                style={{ fontSize: 15 }}
                className="mb-10 font-arialRounded "
              >
                Check email to reset your password, if it does not appear.
                Please check your spam folder.
              </span>

              <div>
                <Button
                  color={"gray2"}
                  variant="contained"
                  onClick={() => dispatch(setCheckEmailAlert(false))}
                >
                  {" "}
                  <div
                    style={{ textTransform: "capitalize" }}
                    className="font-arial"
                  >
                    {" "}
                    <span
                      style={{ fontWeight: 600 }}
                      className="font-arial text-black tracking-wider"
                    >
                      Return to Sign in
                    </span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
