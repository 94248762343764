import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import securityReducer from './security';
import { helloSaga } from './saga/sagas';
import modal from './modal';
import refresh from './refresh';
import profile from './profile';

const sagaMiddleware = createSagaMiddleware();
const Store = configureStore({
  reducer: {
    security: securityReducer,
    modals: modal,
    refresh: refresh,
    profile: profile,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(helloSaga);

export default Store;
