import { useSelector } from 'react-redux';
import { selectCompany } from '../Redux/profile';
import { toProperCase } from './format';

const CommonBreadcrumb = ({ title, parent, button }) => {
  const company = localStorage.getItem('company');
  return (
    <div className=" flex justify-between">
      <div>
        <div
          style={{
            wordSpacing: '2px',
          }}
          className="text-[20px] font- font-bold font-nunito uppercase "
        >
          {title}
        </div>
        <div className="text-[14px] font-nunito text-gray-500">
          {toProperCase(company) + ' - Admin Panel'}
        </div>
      </div>
      <div>{button}</div>
    </div>
  );
};

export default CommonBreadcrumb;
