import React, { useEffect, useState } from 'react';
import CommonBreadcrumb from '../../utility/component/breadCrumb';
import { Star, StarFill, Trash2 } from 'react-bootstrap-icons';
import { Button } from 'bootstrap';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppId } from '../../utility/Redux/profile';
import { selectToken } from '../../utility/Redux/security';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../utility/Redux/modal';

const ItemList = () => {
  const navigate = useNavigate();
  const [itemDataList, setItemList] = useState([]);
  const app_id = useSelector(selectAppId);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const getItemDetails = () => {
    axios
      .post(
        `/api/ItemMasters/listItemMaster`,
        {
          APP_ID: app_id,
          ICODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        console.log(response);
        if (!response.data.error) {
          setItemList(response.data.response);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  useEffect(() => {
    getItemDetails();
  }, []);
  console.log(itemDataList);
  return (
    <div className="p-4 bg-[#f8f8f9] h-full">
      <CommonBreadcrumb
        title="Item List"
        parent="Masters"
        button={
          <button
            onClick={() => {
              navigate('/masters');
            }}
            className="bg-brown p-1 px-4 rounded-sm text-[16px] text-white uppercase font-semibold"
          >
            Back
          </button>
        }
      />

      <div className="grid grid-cols-1 t:grid-cols-2  l:grid-cols-3 d:grid-cols-4 ll:grid-cols-5 gap-2 mt-4 ">
        {itemDataList.map((o) => {
          return (
            <div className="p-3 bg-white w-fit rounded-sm shadow-sm">
              <div className="h-[200px] w-[260px] flex ">
                <img
                  src={o.IMG1_URL ? o.IMG1_URL : 'https://placehold.co/200x200'}
                  className="h-full w-[200px] bg-[#e0e0e0]"
                />
                <div className="ml-2">
                  <img
                    src={
                      o.IMG2_URL ? o.IMG2_URL : 'https://placehold.co/200x200'
                    }
                    className=" h-[50px] w-[50px] bg-[#e0e0e0]"
                  />
                  <img
                    src={
                      o.IMG3_URL ? o.IMG3_URL : 'https://placehold.co/200x200'
                    }
                    className=" h-[50px] mt-2 w-[50px] bg-[#e0e0e0]"
                  />
                  <img
                    src={
                      o.IMG4_URL ? o.IMG4_URL : 'https://placehold.co/200x200'
                    }
                    className=" h-[50px] mt-2 w-[50px] bg-[#e0e0e0]"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex mt-3">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <span key={rating}>
                      {rating <= Number(o.ITEM_RATING) ? (
                        <StarFill color="#ff8084" size={16} />
                      ) : (
                        <Star color="#ff8084" size={16} />
                      )}
                    </span>
                  ))}
                </div>
                <button
                  onClick={() => {
                    navigate('/item_master', {
                      state: o,
                    });
                  }}
                  className="mt-1"
                >
                  <i className="bi bi-pencil-fill "></i>
                </button>
              </div>

              <div className=" font-nunito text-[#928c8c]">{o.ITEMNAME}</div>
              <div className="font-nunito text-black font-bold mb-1">
                {' '}
                ₹{o.SALE_PRICE}/-
              </div>
              <div className="flex space-x-[2px] ">
                <div
                  style={{
                    backgroundColor: o.COLOR,
                  }}
                  className="  h-[15px] w-[15px] rounded-[15px]"
                ></div>
                {/* <div className="h-[15px] w-[15px] rounded-[15px]"></div>
                <div className=" h-[15px] w-[15px] rounded-[15px]"></div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ItemList;
