import React from 'react';
import { useDispatch } from 'react-redux';
import { setAlertWithTitle } from '../Redux/modal';
import uniqid from 'uniqid';
import Dropzone from 'react-dropzone';
import { deleteFile } from '../firebasse/firebase';

const resizeToSquare = (file, callback) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const size = Math.min(img.width, img.height);
      canvas.width = canvas.height = size;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        img,
        (img.width - size) / 2,
        (img.height - size) / 2,
        size,
        size,
        0,
        0,
        size,
        size
      );
      canvas.toBlob(callback, file.type);
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
};
const getPathFromURL = (url) => {
  const urlObj = new URL(url);
  const path = urlObj.pathname.split('/o/')[1].split('?')[0];
  return decodeURIComponent(path);
};
const MultyImageUploader = ({
  selecedFile,
  setSelectedFile,
  selecedFile2,
  setSelectedFile2,
  selecedFile3,
  setSelectedFile3,
  selecedFile4,
  setSelectedFile4,
  itemData,
  setItemData,
}) => {
  const dispatch = useDispatch();

  const handleDrop = (acceptedFiles, setSelectedFile0) => {
    resizeToSquare(acceptedFiles[0], (blob) => {
      const editedName = ` ${uniqid()}-${acceptedFiles[0].name}`;
      const resizedFile = new File([blob], editedName, {
        type: acceptedFiles[0].type,
      });
      setSelectedFile0(resizedFile);
    });
  };

  return (
    <div className="flex">
      <div
        style={{
          width: '200px',
          height: '200px',
        }}
        className="rounded-sm overflow-hidden"
      >
        <div className="flex justify-end">
          {selecedFile && (
            <button
              onClick={() => setSelectedFile(null)}
              fontSize={'medium'}
              className="absolute bg-gray-100"
            >
              <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
            </button>
          )}
          {itemData.IMG1_URL && (
            <button
              onClick={() => {
                const path = getPathFromURL(itemData.IMG1_URL);
                deleteFile(path);
                setItemData((o) => {
                  return {
                    ...o,
                    IMG1_URL: '',
                  };
                });
              }}
              fontSize={'medium'}
              className="absolute bg-gray-100"
            >
              <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
            </button>
          )}
        </div>
        <Dropzone
          onDrop={(acceptedFiles) => handleDrop(acceptedFiles, setSelectedFile)}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="justify-center items-center h-full"
            >
              <div>
                <img
                  src={
                    selecedFile
                      ? URL.createObjectURL(selecedFile)
                      : itemData.IMG1_URL
                      ? itemData.IMG1_URL
                      : 'https://placehold.co/200x200'
                  }
                  alt="Upload"
                />
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="ml-3">
        {[setSelectedFile2, setSelectedFile3, setSelectedFile4].map(
          (setFile, index) => (
            <div key={index} className="mt-2 rounded-sm overflow-hidden">
              <div className="flex justify-end">
                {index === 0 && selecedFile2 && (
                  <button
                    onClick={() => setFile(null)}
                    className="absolute bg-gray-100"
                  >
                    <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
                  </button>
                )}

                {index === 0 && itemData.IMG2_URL && (
                  <button
                    onClick={() => {
                      const path = getPathFromURL(itemData.IMG2_URL);
                      deleteFile(path);
                      setItemData((o) => {
                        return {
                          ...o,
                          IMG2_URL: '',
                        };
                      });
                    }}
                    fontSize={'medium'}
                    className="absolute bg-gray-100"
                  >
                    <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
                  </button>
                )}
                {index === 1 && selecedFile3 && (
                  <button
                    onClick={() => setFile(null)}
                    className="absolute bg-gray-100"
                  >
                    <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
                  </button>
                )}
                {index === 1 && itemData.IMG2_URL && (
                  <button
                    onClick={() => {
                      const path = getPathFromURL(itemData.IMG3_URL);
                      deleteFile(path);
                      setItemData((o) => {
                        return {
                          ...o,
                          IMG3_URL: '',
                        };
                      });
                    }}
                    fontSize={'medium'}
                    className="absolute bg-gray-100"
                  >
                    <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
                  </button>
                )}
                {index === 2 && selecedFile4 && (
                  <button
                    onClick={() => setFile(null)}
                    className="absolute bg-gray-100"
                  >
                    <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
                  </button>
                )}
                {index === 2 && itemData.IMG3_URL && (
                  <button
                    onClick={() => {
                      const path = getPathFromURL(itemData.IMG4_URL);
                      deleteFile(path);
                      setItemData((o) => {
                        return {
                          ...o,
                          IMG4_URL: '',
                        };
                      });
                    }}
                    fontSize={'medium'}
                    className="absolute bg-gray-100"
                  >
                    <i className="bi bi-x text-[22px] flex items-center text-red-400 font-bold"></i>
                  </button>
                )}
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => handleDrop(acceptedFiles, setFile)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ width: '80px', height: '80px' }}
                    className="flex justify-center items-center h-full bg-gray-100"
                  >
                    <img
                      src={
                        index === 0
                          ? selecedFile2
                            ? URL.createObjectURL(selecedFile2)
                            : itemData.IMG2_URL
                            ? itemData.IMG2_URL
                            : 'https://placehold.co/200x200?text=2'
                          : index === 1
                          ? selecedFile3
                            ? URL.createObjectURL(selecedFile3)
                            : itemData.IMG3_URL
                            ? itemData.IMG3_URL
                            : 'https://placehold.co/200x200?text=3'
                          : selecedFile4
                          ? URL.createObjectURL(selecedFile4)
                          : itemData.IMG4_URL
                          ? itemData.IMG4_URL
                          : 'https://placehold.co/200x200?text=4'
                      }
                      alt="Upload"
                    />
                  </div>
                )}
              </Dropzone>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MultyImageUploader;
