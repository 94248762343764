import React, { useState } from 'react';
import ImageUpload from '../utility/component/function/imageUpload';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { TiDelete } from 'react-icons/ti';
import axios from 'axios';
import moment from 'moment';
import { selectToken } from '../utility/Redux/security';
import { CustomeButton } from '../utility/component/buttons';


import {
  setALertStatus,
  setAlertMsg,
  setSessionExpired,
} from '../utility/Redux/modal';


const CreatePost = () => {
  const [selectedFile, setSelectedFile] = useState('');
  const [message, setMessage] = useState('');
  const [border, setborder] = useState('#d7dfe7');
  const tokan = useSelector(selectToken);
  const dispatch = useDispatch();

  const handleUpload = async () => {
    const id = uniqid();
    const formData = new FormData();
    {
      selectedFile
        ? formData.append('image', selectedFile)
        : formData.append('image', null);
    }
    {
      message
        ? formData.append('message', message)
        : formData.append('message', null);
    }
    formData.append('video', null);
    formData.append('date', moment().format('YYYY-MM-DD'));
    formData.append('post_type', 'SELF');
    formData.append('approved', 'YES');
    formData.append('id', id);

    try {
      const response = await axios.post(
        '/api/uploadImage/uploadImage',
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokan}`,
          },
        }
      );

      setSelectedFile('');
      setMessage('');
      dispatch(
        setAlertMsg(
          'Congratulations! Your post has been successfully shared with the world.'
        )
      );
      dispatch(setALertStatus(true)); // handle the response
    } catch (error) {
      console.error(error);
      if (error.response?.data?.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
       
      setSelectedFile('');
      setMessage('');
      dispatch(setAlertMsg('Something went wrong'));
      dispatch(setALertStatus(true)); // handle the response
    }
  };

  const cancel = () => {
    setSelectedFile('');
    setMessage('');
  };

  return (
    <div
      style={{
        // backgroundColor:'#F4F4F3',
        height: window.innerHeight,
        padding: 15,
      }}
    >
      <h1 style={{ fontSize: 20, fontWeight: 'bolder' }}>Create Post</h1>

      <div
        style={{
          margin: 20,
          backgroundColor: 'white',
          height: '70%',
          boxShadow: 'revert-layer',
          borderRadius: 10,
          overflow: 'hidden',
          borderColor: border,
        }}
        className="border"
      >
        <textarea
          aria-placeholder="s"
          onFocus={() => setborder('#94a3b8')}
          onBlur={() => setborder('#e2e8f0')}
          style={{
            height: '100%',
            width: '100%',
            padding: 10,
            borderRadius: 10,
            outline: 0,
            overflow: 'hidden',
          }}
          placeholder={'Write here..'}
          value={message}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              setMessage((prevText) => prevText + '\n');
            }
          }}
          onChange={(t) => setMessage(t.target.value)}
        ></textarea>
      </div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: 20,
          marginLeft: 20,
          height: '10%',
          alignItems: 'center',
        }}
      >
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div
            style={{
              backgroundColor: 'lightgrey',
              width: 'auto',
              height: (window.innerHeight / 100) * 9,
              overflow: 'hidden',
              borderRadius: 10,
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            {selectedFile ? (
              <>
                <div
                  onClick={() => setSelectedFile('')}
                  style={{ backgroundColor: '#F4F4F3', position: 'absolute' }}
                >
                  <TiDelete color={'#ff6f0f'} size={'30px'} />
                </div>

                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt={`Selected`}
                  style={{
                    width: 'auto',
                    height: (window.innerHeight / 100) * 9,
                  }}
                />
              </>
            ) : null}
          </div>
          <div
            style={{
              marginLeft: 20,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              color: '#ff6f0f',
            }}
          >
            <ImageUpload setSelectedFile={setSelectedFile} />
          </div>
        </div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <CustomeButton
            title={'Publish'}
            onClick={handleUpload}
            backgroundColor={'#f5874f'}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          />
          <CustomeButton
            title={'Cancel'}
            onClick={cancel}
            style={{ paddingLeft: 6, paddingRight: 6, marginLeft: 10 }}
            backgroundColor={'#F1F5F9'}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
