import React, { Fragment, useEffect, useRef, useState } from 'react';
import CommonBreadcrumb from '../../utility/component/breadCrumb';
import Catogery from './catogery';




const CatogeryMaster = () => {

  const titleStyles= 'text-gray-600 text-sm'



  return (
    <div className='p-4 h-full overflow-y-auto'>
 <Catogery />
    </div>
  );
};

export default CatogeryMaster;
