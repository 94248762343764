import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';

const ActivationAccount = () => {
  const searchParams = new URLSearchParams(window.location.search);
  // const param1 = searchParams.get('company_email');
  const userEmail = searchParams.get('user_email');
  const activationCode = searchParams.get('activate_code');

  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  // const hmac = CryptoJS.HmacSHA256(message, secret);
  const hmac = CryptoJS.HmacSHA256(userEmail, secretPass);
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = hmac.toString(CryptoJS.enc.Base64);

  const ActivateUser = () => {
    axios
      .post(
        `/api/users/userInfo`,
        //  `http://localhost:8080/api/users/userInfo`,
        {
          user_email: userEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        if (response.data.response[0]) {
          axios
            .post(
              '/api/users/activateUser',
              {
                user_email: userEmail,
                activate_code: activationCode,
                user_name: response.data.response[0].user_name,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${hmacString}`,
                },
              }
            )
            .then((d) => {
              console.log('activated');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activationCode) {
      ActivateUser();
    }
  }, []);
  return <div className="h-screen w-screen p-4">Account is in Process</div>;
};

export default ActivationAccount;
