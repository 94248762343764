// Import Firebase and initialize it with your config (as shown in previous response)
import { initializeApp } from 'firebase/app';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

// Initialize Firebase with your config
const firebaseConfig = {
  apiKey: 'AIzaSyAbJG9ncx--2lRyGZwdcEWt8IL00p3m1bw',
  authDomain: 'genius-office.firebaseapp.com',
  projectId: 'genius-office',
  storageBucket: 'genius-office.appspot.com',
  messagingSenderId: '378785498497',
  appId: '1:378785498497:web:ebfe6c1115c0b081b71480',
  measurementId: 'G-DJBSL9DK35',
};

const app = initializeApp(firebaseConfig);

// Get a reference to Firebase Storage
const storage = getStorage(app);

async function UploadFile(file, path, name) {
  const storageRef = ref(storage, path + name);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    return snapshot;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

async function deleteFile(path) {
  const storageRef = ref(storage, path);

  try {
    // Delete the file
    await deleteObject(storageRef);
    console.log('File deleted successfully');
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
}

const DownloadFile = async (path) => {
  const imageRef = ref(storage, path);
  try {
    const imageUrl = await getDownloadURL(imageRef);

    return imageUrl;
    // Now you can use `imageUrl` to display the image in your application.
  } catch (error) {
    console.error('Error getting image URL:', error);
    return error;
  }
};

export { UploadFile, DownloadFile, deleteFile };
